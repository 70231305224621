<template>
  <CContainer>
    <nav-bar v-bind:headerName="headerName"></nav-bar>
    <hr class="mt-0" />
    <div class="card text-center">
      <h4>
        <img
          alt=""
          :src="logo"
          class="img-fluid"
          width="25%"
          onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/609/609752.png';"
        />
      </h4>
      <h5 class="font-weight-bold">{{ shopName }}</h5>
      <h6>ยอดชำระ {{ amount }} บาท</h6>
    </div>
    <div class="row">
      <div class="col">
        <div
          class="card border text-center"
          style="padding: 10px"
          @click="mPayCreditCard()"
        >
          <h6 class="mt-1">
            <CIcon name="cil-credit-card" size="2xl"></CIcon>
          </h6>
          <h6>บัตรเครดิต</h6>
        </div>
      </div>
      <div class="col">
        <div
          class="card border text-center"
          style="padding: 10px"
          @click="goToPromptpay()"
        >
          <h6 class="mt-1">
            <CIcon name="cil-qr-code" size="2xl"></CIcon>
          </h6>
          <h6>พร้อมเพย์</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div
          class="card border text-center"
          style="padding: 10px"
          @click="mPayInternetBanking()"
        >
          <h6 class="mt-1">
            <CIcon name="cil-bank" size="2xl"></CIcon>
          </h6>
          <h6>อินเตอร์เน็ตแบงก์กิ้ง</h6>
        </div>
      </div>
      <div class="col">
        <div
          class="card border text-center"
          style="padding: 10px"
          @click="ecashier()"
        >
          <h6 class="mt-1">
            <CIcon name="cil-money" size="2xl"></CIcon>
          </h6>
          <h6>E-Cashier</h6>
        </div>
      </div>
    </div>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '../menu/NavBar'
import payment from '@/services/payment'
import util from '@/util/util'

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      headerName: 'ช่องทางชำระเงิน',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopLineObjectId
      )
    },
    shopName() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].shopName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].remoteImagePath
      } else {
        return ''
      }
    },
    amount() {
      let totalAmount = sessionStorage.getItem('amount')
      return util.convertCurrency(Number(totalAmount))
    },
  },
  methods: {
    mPayCreditCard() {
      let orderId = util.generateNumber()
      let urlsuccess =
        process.env.VUE_APP_PAYMENT_GATEWAY_URL +
        '/mpay/payment/v1.0/' +
        this.uid +
        '/webhook'

      let data = {
        order_id: orderId,
        product_name: 'Silom Online Order: ' + orderId,
        service_id: '21214302101133752',
        channel_type: 'WEBSITE',
        cust_id: ' C0000001',
        amount: Number(this.amount),
        currency: 'THB',
        ref_1: 'reference1' + orderId,
        ref_2: 'reference2' + orderId,
        '3ds': {
          '3ds_required': true,
          '3ds_url_success': urlsuccess,
          '3ds_url_fail': 'http://localhost:7001/pickup/failure',
          //'3ds_url_fail': 'https://smartmenu-silomdev.web.app/pickup/failure',
        },
        form_type: 'FORM',
      }

      payment({
        url: '/mpay/payment/v1.0/' + this.uid + '/creditcard',
        method: 'post',
        data: data,
      }).then((res) => {
        console.log(res.data)
        if (res.data.data.form_url) {
          window.location.replace(res.data.data.form_url)
        }
      })
    },
    mPayInternetBanking() {
      let orderId = util.generateNumber()

      let urlsuccess =
        process.env.VUE_APP_PAYMENT_GATEWAY_URL +
        '/mpay/payment/v1.0/' +
        this.uid +
        '/webhook'

      let data = {
        service_id: '20426502013325267',
        order_id: orderId,
        channel_type: 'WEBSITE',
        cust_id: 'cust_id_00001',
        product_name: 'Silom Online Order: ' + orderId,
        bank_code: '014',
        ref_1: 'ref_1',
        ref_2: 'ref_2',
        currency: 'THB',
        amount: Number(this.amount),
        metadata: {
          color: 'ref',
        },
        redirect_urls: {
          url_success: urlsuccess,
          url_fail: 'https://smartmenu-silomdev.web.app/pickup/failure',
        },
      }
      payment({
        url: '/mpay/payment/v1.0/' + this.uid + '/internetbanking',
        method: 'post',
        data: data,
      }).then((res) => {
        console.log(res.data)
        if (res.data.data.authorize_url) {
          window.location.replace(res.data.data.authorize_url)
        }
      })
    },
    ecashier() {
      let merchantKey = 'D0qX9ScWnA0N9ZVOuPNXsVSCieRWclORmVYjyeIZ'
      let orderId = util.generateNumber()
      let urlcallback =
        process.env.VUE_APP_PAYMENT_GATEWAY_URL +
        '/callback/v1.0/' +
        orderId +
        '/webhook'

      let data = {
        sub: merchantKey,
        id: orderId,
        total: Number(this.amount),
        url: urlcallback,
        order_desc: this.shopName,
        subtotal: Number(this.amount / 1.07).toFixed(2),
        vat: Number((this.amount / 1.07) * 0.07).toFixed(2),
        vat_rate: '7.00',
        iat: new Date().getTime(),
      }

      payment({
        url: '/thaidotcom/payment/v1.0/ecashier',
        method: 'post',
        data: data,
      }).then((res) => {
        console.log(res.data.data)
        if (res.data.data) {
          window.location.replace(res.data.data)
        }
      })
    },
    goToCreditCard() {
      this.$router.push('/payment/credit-card')
    },
    goToPromptpay() {
      this.$router.push('/payment/promptpay')
    },
    goToInternetBanking() {
      this.$router.push('/payment/internet-banking')
    },
  },
}
</script>
